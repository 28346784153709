@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700;900&display=swap");
/* @import url("../../../node_modules/react-syntax-highlighter/dist/esm/styles/hljs"); */





.App {
  max-width: 700px;
  margin: 5rem auto;
  font-family: Poppins, sans-serif;
}

/* TitTap Editor Design */
.ProseMirror {
  padding: 10px;
  border-bottom: 1px solid grey;
  background: white;
  border-radius: 0 0 5px 5px;
  min-height: 100px;
}
.ProseMirror:focus {
  border: none;
  outline: 1px solid grey;
}

.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 2rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}

.ProseMirror code {
  /* background-color: black; */
  color: #616161;
} 

.ProseMirror pre {
  background : #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ProseMirror code {
   /* color: rgb(17, 204, 27);  */
   
   
   color: #f90;
   
  padding: 0; 
  /* background: black; */
  /* border: 1px solid green;
  border-radius: 5px; */

   font-size: 0.8rem; 
} 


.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 3px solid #999999;
}

.ProseMirror hr {
  border: none;
  border-top: 3px solid #999999;
  margin: 2rem 0;
}

/* ............................ */
.textEditor {
  border-radius: 5px;
  border: 1px solid grey;
}
.menuBar {
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menuBar button {
  font-size: 16px;
  margin: 7px;
  margin-right: .8rem;
  padding: .3rem .7rem;
  /* outline: 1px solid orange; */
  border: none;
  background: none;
  color: rgb(70, 70, 70);
  cursor: pointer;
}
.menuBar button:last-child {
  margin-right: 7px;
}

.heading3 {
  font-size: 15px;
}

button.is_active {
  background: rgb(248, 153, 105);
  border-radius: 2px;
}
