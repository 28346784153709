.dashboard-overview {
    padding: 20px;
}

.overview-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.overview-card {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.overview-card h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.overview-card p {
    font-size: 16px;
    margin: 0;
}
