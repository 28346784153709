.existing-container{
    /* border: .1rem solid rgb(236, 125, 34); */
    border-left: 1px solid #333;
    max-height: 40rem;
    height: 40rem;
    overflow-y:scroll;
    

}


.existing-element{
    /* border: .1rem solid rgb(236, 125, 34); */
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    border-radius: .5rem;
    margin-bottom: 1rem;
    padding: .5rem;

}

.existing-text{
    color: rgb(0, 0, 0);
}
