.message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  color: #fff;
  z-index: 999;
  max-width: 300px;
}

.message.success {
  background-color: #4caf50;
}

.message.error {
  background-color: #f44336;
}

.message.warning {
  background-color: #ff9800;
}

.close {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}
