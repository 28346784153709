.styled-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  .styled-table th, .styled-table td {
    border: 1px solid rgb(255, 145, 0);
    padding: 8px;
    text-align: left;
  }
  
  .styled-table th {
    background-color: rgb(255, 145, 0);
    color: white;
  }
  
  .delete{
    margin:.5rem;
    background-color: #c40b0b; 
    border: none;
    border-radius: 10px;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 2px 2px;
    cursor: pointer;
  }

  .edit{
    margin:.5rem;
    background-color: #000000b7; 
    border: none;
    border-radius: 10px;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 2px 2px;
    cursor: pointer;
  }

  
  .edit:hover {
    background-color: #ce4f06;
  }
  
  .left-btn{
    color: #e48912;
    padding: .5rem;
    border:1px solid #e48912;
    min-width: 8rem;
  }

  .right-btn{
    color: #ffffff;
    padding: .5rem;
    border:1px solid #f1b362;
    background-color:#f1b362;
    min-width: 8rem;
  }



  .left-btn:hover,.right-btn:hover{
    color: white;
    background-color:#e48912;
  }
  .right-btn{
    float: right;
  }

  .deleted-post {
    background-color: rgba(231, 51, 51, 0.158);
    
  }

  .active-post{
    background-color: rgba(10, 192, 92, 0.185);
  }